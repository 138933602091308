<template>
    <div>
        <temp
            v-if="model"
            :pageTitle="pageTitle"
            :model.sync="model"
            @on-submit="createExplain"
        />
    </div>
</template>

<script>
import Temp from '@admin/components/Explain'
import flatry from '@admin/utils/flatry'
import ExplainService from '@admin/services/ExplainService'

export default {
  name: '',
  props: [''],
  data () {
    return {
      pageTitle: '储值说明',
      model: null
    }
  },

  components: { Temp },
  async created () {
    let param = {
      type: 3, // 3 是储值说明
      title: '',
      e_body: '',
      status: true
    }
    const { data } = await flatry(ExplainService.explain(3))
    if (data.data.model) {
      this.model = data.data.model
      this.model.e_body = data.data.e_body
    } else {
      this.model = param
    }
  },
  methods: {
    async createExplain (model, success, callback) {
      const { data } = await flatry(ExplainService.explain(model.type, model))
      if (data) {
        this.$message.success(data.msg)
      }
    }
  },

  watch: {}
}
</script>
<style lang='' scoped>
</style>
