import Vue from 'vue'

const ExplainService = {
  explain (type = null, model = null) {
    const endpoint = 'explain/explain'

    if (model === null) {
      return Vue.http.get(endpoint, { id: type })
    }

    return Vue.http.post(endpoint, model, { id: type })
  }
}

export default ExplainService
