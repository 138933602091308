<template>
    <div class="box">
        <div class="box-header">
            <h4>{{ pageTitle }}</h4>
        </div>
        <el-form
            v-if="formModel"
            :rules="formRules"
            :model="formModel"
            ref="formModel"
            label-width="140px"
            label-suffix="："
            @submit.native.stop.prevent="handleFormSubmit('formModel')"
        >
            <el-form-item label="标题">
                <el-col :span="9">
                    <el-input
                        v-model="formModel.title"
                        placeholder=""
                    ></el-input>

                </el-col>
            </el-form-item>

            <el-form-item label="内容">
                <el-col :span="16">
                    <el-input
                        type="textarea"
                        :rows="20"
                        v-model="formModel.e_body"
                        placeholder=""
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="状态">
                <el-switch
                    v-model="formModel.status"
                    active-text=""
                    inactive-text=""
                >
                </el-switch>
            </el-form-item>

            <el-form-item label="">
                <el-button
                    type="primary"
                    size="medium"
                    native-type="submit"
                    :loading="loading"
                >保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
  name: 'Expalin',
  props: {
    pageTitle: {
      type: String,
      default: '内容创建'
    },
    model: {}
  },
  data () {
    return {
      formRules: null,
      loading: false,
      formModel: null
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.model)
  },
  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
